/**
 * Set up global and initial styles in this file.
 * This is imported once on the app root.
 */
@import "grid";
@import "typography";
@import "visibility";

html {
	background: palette(black);
}

body {
	@include styled-scrollbar(inverted);
}

#root {
	background: palette(starlight);
	overflow-x: hidden;
}

// use border-box sizing for everything
html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}
