$starlight: #eeeede;
$white: #ffffff;
$black: #1a1a1a;
$neptune: #172b53;
$mercury: #c0272d;
$galaxy: linear-gradient(315deg, $mercury, $neptune);

$green: #1ba92a;

// Midpoint between the galaxy gradient.
// Can also be calculated using gradient-color-at($neptune, $mercury, 0.5).
$galaxyMidpoint: #6c2940;

$palettes: (
	black: (
		pure: hsl(0, 0%, 0%),
		dark: hsl(0, 0%, 5%),
		base: hsl(0, 0%, 10%),
		light: hsl(0, 0%, 20%),
		xlight: hsl(0, 0%, 30%),
		xxlight: hsl(0, 0%, 40%)
	),
	gray: (
		xxdark: hsl(0, 0%, 45%),
		xdark: hsl(0, 0%, 50%),
		dark: hsl(0, 0%, 55%),
		base: hsl(0, 0%, 60%),
		light: hsl(0, 0%, 65%),
		xlight: hsl(0, 0%, 70%),
		xxlight: hsl(0, 0%, 75%)
	),
	white: (
		xxxdark: hsl(0, 0%, 84%),
		xxdark: hsl(0, 0%, 90%),
		xdark: hsl(0, 0%, 94%),
		dark: hsl(0, 0%, 97%),
		base: hsl(0, 0%, 100%)
	),
	starlight: (
		xxdark: darken($starlight, 40),
		xdark: darken($starlight, 30),
		dark: darken($starlight, 10),
		base: $starlight,
		light: lighten($starlight, 5),
		xlight: lighten($starlight, 10),
		xxlight: lighten($starlight, 20)
	),
	neptune: (
		xxdark: darken($neptune, 40),
		xdark: darken($neptune, 30),
		dark: darken($neptune, 10),
		base: $neptune,
		light: lighten($neptune, 10),
		xlight: lighten($neptune, 30),
		xxlight: lighten($neptune, 50)
	),
	mercury: (
		xxdark: darken($mercury, 40),
		xdark: darken($mercury, 30),
		dark: darken($mercury, 10),
		base: $mercury,
		light: lighten($mercury, 10),
		xlight: lighten($mercury, 30),
		xxlight: lighten($mercury, 50)
	),
	green: (
		xxdark: darken($green, 40),
		xdark: darken($green, 30),
		dark: darken($green, 10),
		base: $green,
		light: lighten($green, 10),
		xlight: lighten($green, 30),
		xxlight: lighten($green, 50)
	),
);

// helper for getting a color from the palette
@function palette($palette, $tone: 'base') {
	@return map-get(map-get($palettes, $palette), $tone);
}

// helper for getting a color between a gradient:
// https://gist.github.com/paulozoom/23ebed1c8c07d9455413
@function gradient-color-at($start, $end, $point) {
	$error-message: "$point needs to be a percentage (0% to 100%) or a unitless number (0 to 1)";
	$unit: unit($point);
	// $point needs to be unitless or percentage
	@if $unit != "" and $unit != "%" {
	  @error $error-message;
	}
	@else {
	  // convert $point to fraction
	  @if $unit == "%" { $point: $point / 100%; }
	  // check if within bounds
	  @if $point < 0 or $point > 1 { @error $error-message; }
	}
  
	$delta_h:         hue($end) - hue($start);
	$delta_s:  saturation($end) - saturation($start);
	$delta_l:   lightness($end) - lightness($start);
	$delta_a:       alpha($end) - alpha($start);
  
	@return adjust-color(
	  $color:       $start, 
	  $hue:         $point * $delta_h, 
	  $saturation:  $point * $delta_s, 
	  $lightness:   $point * $delta_l, 
	  $alpha:       $point * $delta_a
	);
  }
