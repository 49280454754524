// clearfix helper
@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

// gradates a text color with the palette gradient
@mixin galaxy_text {
	color: palette(mercury);
	@supports (background-clip: text) {
		background: $galaxy;
		background-clip: text;
		color: transparent;
	}
}

// creates an on-hover/focus underline for links
@mixin link-underline($color: palette(black)) {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background: $color;
		opacity: 0;
		transform: scaleX(0.01);
		transform-origin: bottom right;
		transition: transform 500ms $ease-out-quint, opacity 400ms 100ms $ease-out-quint;
	}

	&:hover, &:focus {
		color: $color;
		outline: none;

		&::before {
			opacity: 1;
			transform: scaleX(1);
			transform-origin: bottom left;
			transition: transform 500ms $ease-out-quint, opacity 100ms $ease-out-quint;
		}
	}
}

// custom scrollbar styles for webkit
@mixin styled-scrollbar($style: default) {
	&::-webkit-scrollbar {
		width: 0.5rem;
		height: 0.5rem;
	}

	$track: palette(white);
	$thumb: palette(gray);
	$thumbHover: palette(black);

	@if $style == inverted {
		$track: palette(black);
		$thumb: palette(starlight);
		$thumbHover: palette(white);
	}

	&::-webkit-scrollbar-track {
		background: $track;
		border-left: 1px solid $track;
	}
	
	&::-webkit-scrollbar-thumb {
		background: $thumb;
		border-radius: 1rem;
		border: 2px solid $track;
	
		&:hover {
			background: $thumbHover;
		}
	}
}
