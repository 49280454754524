html, body {
	font-size: 14px;
	font-family: 'Inter', sans-serif;
	font-weight: 400;

	@include respond-to(md up) {
		font-size: 16px;
	}

	@include respond-to(xl up) {
		font-size: 18px;
	}
}

h1, h2, h3, h4 {
	display: inline-block;
	font-family: 'Metropolis', sans-serif;
	font-size: 2rem;
	font-weight: 800;

	+ .subheading {
		margin: -0.5em 0 2rem;
		line-height: 1.5;
		color: palette(black);
	}

	&.inverted {
		color: palette(white);
	
		+ .subheading {
			color: palette(white);
			opacity: 0.5;
		}
	}

	&:not(.inverted) {
		@include galaxy_text;
	}

	&.muted {
		font-size: 0.85rem;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		font-weight: 800;
		color: palette(black, xlight);
	}
}

h2 {
	font-size: 1.75rem;
}

h3 {
	font-size: 1.5rem;
}

h4 {
	font-size: 1rem;
}

p, li {
	line-height: 1.5;
}

::selection {
	background: rgba(palette(mercury), 0.75);
	color: palette(white);
}
