/**
 * Breakpoint helpers.
 */

$breakpoints: (
	xxs: 240px,
	xs: 480px,
	sm: 640px,
	md: 960px,
	lg: 1280px,
	xl: 1600px,
	xxl: 1920px,
	xxxl: 2240px,
	xxxxl: 2560px
);

// offset step size
$breakpoint-offset: 48px;

// helper function for accessing breakpoints
@function breakpoint($size: md, $offset: 0, $pixel-offset: 0) {
	@return #{ map-get($breakpoints, $size) + ($offset * $breakpoint-offset) + $pixel-offset };
}

// helper mixin for building breakpoint-driven media queries
@mixin respond-to($sizedir) {
	$maxmin: min-width;
	$value: #{ breakpoint(nth($sizedir, 1)) };

	@if (nth($sizedir, 2) == down) {
		$maxmin: max-width;
		$value: #{ breakpoint(nth($sizedir, 1), 0, -1px) };
	}

	@media only screen and ($maxmin: $value) {
		@content;
	}
}
