// custom visibility classes
@each $name, $size in $breakpoints {
	@include respond-to($name down) {
		.h-#{ $name }-down { display: none    !important; }
		.v-#{ $name }-down { display: initial !important; }
	}

	@include respond-to($name up) {
		.h-#{ $name }-up { display: none    !important; }
		.v-#{ $name }-up { display: initial !important; }
	}
}
