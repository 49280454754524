@import "styles/init";

@import "~normalize.css/normalize.css";
@import "styles/base";


.suspense-preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: palette(starlight);
}
